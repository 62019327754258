import "babel-polyfill";
import core from 'core.js';



(function(){
    
    console.log("app initialization");
    
    
    core.init();
    
    
    importModule("tooltip.js");
    
    
    
    let _modules = document.querySelectorAll('[data-js-module]');
    
    if( _modules ){
        for( let i = 0; i < _modules.length; i++ ){
            let _moduleName = _modules[i].getAttribute('data-js-module');
            // TO DO params
            //let _moduleParams = _modules[i].getAttribute('data-js-params');
            //_moduleParams = _moduleParams.split()
            importModule(_moduleName+".js", {});
        }
    }
    
})();


function importModule(_moduleName, _params, func){
    console.log("found module:", _moduleName +",", "importing...");
    // /* webpackChunkName: "_moduleName" */
    import(`./modules/${_moduleName}`).then( 
            src => func && src.default[func] ? console.log( src.default[func](_params) ) : src.default.init ? src.default.init(_params) : console.log(_moduleName, "loaded") 
    );
} 