const publicAPI = {
  init: init,
  push: dataLayerPush,
};

export default publicAPI;

function init(params) {
  if (document.querySelectorAll('[data-js="submenu"]').length > 0) submenu();

  if (document.querySelectorAll('[data-js="mobmenu"]').length > 0) mobmenu();

  if (document.querySelectorAll('[data-js="show-form"]').length > 0) showForm();

  if (document.querySelectorAll('[data-js="show-more-cat"]').length > 0)
    showMoreCat();

  if (document.querySelectorAll('[data-js="left-submenu"]').length > 0)
    showElement("left-submenu");

  if (document.querySelectorAll('[data-js="iconlist"]').length > 0)
    showElement("iconlist");

  if (document.querySelectorAll('[data-js="modellist"]').length > 0)
    showElement("modellist");
}

var submenu = function () {
  var header = document.querySelector("header"),
    body = document.querySelector("body"),
    mainNav = header.querySelector(".header__mainnav"),
    menuBtns = header.querySelectorAll('[data-js="submenu"]'),
    mainNavBtns = mainNav.querySelectorAll('[data-js="submenu"]'),
    asideNavBtns = [...menuBtns].filter(
      (item) => ![...mainNavBtns].includes(item)
    ),
    secondSubmenuItems = header.querySelectorAll(".header__lvl2el"),
    thirdSubmenuItems = header.querySelectorAll(".header__lvl3el");

  //Polyfill for browsers that do not support Element.closest(), but carry support for element.matches() (or a prefixed equivalent, meaning IE9+)
  if (!Element.prototype.matches)
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;

  if (!Element.prototype.closest)
    Element.prototype.closest = function (s) {
      var el = this;
      if (!document.documentElement.contains(el)) return null;
      do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };

  function showSubmenu(e) {
    var target = e.target,
      menuBtn = target.closest('[data-js="submenu"]');

    e.stopPropagation();

    if (!menuBtn.classList.contains("open")) {
      closeSiblings(menuBtn);
      menuBtn.classList.add("open");
      body.addEventListener("click", closeAll);
    } else {
      menuBtn.classList.remove("open");
    }
  }


  for (var i = 0; i < mainNavBtns.length; i++) {
    mainNavBtns[i].addEventListener("click", function (e) {
      //closing menu when 2nd level link is clicked
      menuClosingLinks(secondSubmenuItems);
      //closing menu if any 3nd level link is clicked
      menuClosingLinks(thirdSubmenuItems);
      closeOtherMenu(asideNavBtns);
      showSubmenu(e);
    });
  }

  for (var i = 0; i < asideNavBtns.length; i++) {
    asideNavBtns[i].addEventListener("click", function (e) {
      closeOtherMenu(mainNavBtns);
      if (!body.classList.contains("mobile-menu")) {
        showSubmenu(e);
      }
    });
  }

  function closeAll() {
    for (var i = 0; i < menuBtns.length; i++) {
      menuBtns[i].closest('[data-js="submenu"]').classList.remove("open");
    }
    body.removeEventListener("click", closeAll);
  }

  function closeSiblings(elem) {
    var siblings = [...elem.parentNode.children].filter(
      (item) => item !== elem
    );
    for (var i = 0; i < siblings.length; i++) {
      siblings[i].classList.remove("open");
    }
  }

  function closeOtherMenu(otherMenus) {
    for (var i = 0; i < otherMenus.length; i++) {
      if (otherMenus[i].classList.contains("open")) {
        otherMenus[i].classList.remove("open");
      }
    }
  }

  function menuClosingLinks(items) {
    for (var i = 0; i < items.length; i++) {
      var link = items[i].querySelector("a");
      link.addEventListener("click", function (e) {
        closeAll();
      });
    }
  }
};

var mobmenu = function () {
  var mobmenuBtn = document.querySelector('[data-js="mobmenu"]'),
    headerOverlay = document.querySelector('[data-js="header-overlay"]'),
    headerNav = headerOverlay.querySelector("nav"),
    closeMobmenuBtn = headerNav.querySelector('[data-js="close-mobmenu"]'),
    secondSubmenuItems = headerNav.querySelectorAll(".header__lvl2el"),
    thirdSubmenuItems = headerNav.querySelectorAll(".header__lvl3el"),
    body = document.querySelector("body");
  const mQuery = window.matchMedia("(min-width: 1200px)");

  //fitting phonenumber box absolute position
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  //closing menu when 2nd level link is clicked
  menuClosingLinks(secondSubmenuItems);
  //closing menu if any 3nd level link is clicked
  menuClosingLinks(thirdSubmenuItems);

  mobmenuBtn.addEventListener("click", function (e) {
    e.stopPropagation();

    if (!headerOverlay.classList.contains("open")) {
      openMobmenu();
    } else {
      closeMobmenu();
    }
  });

  headerNav.addEventListener("click", function (e) {
    e.stopPropagation();
  });

  mQuery.addEventListener("change", handleResize);

  function openMobmenu() {
    headerOverlay.classList.add("open");
    body.classList.add("body-overlay");
    body.classList.add("mobile-menu");
    body.addEventListener("click", closeMobmenu);
    closeMobmenuBtn.addEventListener("click", closeMobmenu);
  }
  function closeMobmenu() {
    headerOverlay.classList.remove("open");
    body.classList.remove("body-overlay");
    body.classList.remove("mobile-menu");
    body.removeEventListener("click", closeMobmenu);
  }
  function handleResize(e) {
    if (e.matches) {
      closeMobmenu();
    }
  }

  function menuClosingLinks(items) {
    for (var i = 0; i < items.length; i++) {
      var link = items[i].querySelector("a");
      link.addEventListener("click", function (e) {
        closeMobmenu();
      });
    }
  }
};

var showForm = function () {
  var showFormBtn = document.querySelector('[data-js="show-form"]'),
    body = document.querySelector("body"),
    formBox = document.querySelector(".formbox__form"),
    form = formBox.querySelector("form"),
    closeFormBtn = form.querySelector('[data-js="close-form"]');

  showFormBtn.addEventListener("click", function (e) {
    e.stopPropagation();
    if (!formBox.classList.contains("open")) {
      formBox.classList.add("open");

      body.addEventListener("click", closeMobmenu);
      closeFormBtn.addEventListener("click", closeMobmenu);
    } else {
      closeMobmenu();
    }
  });
  form.addEventListener("click", function (e) {
    e.stopPropagation();
  });

  function closeMobmenu() {
    formBox.classList.remove("open");
    body.removeEventListener("click", closeMobmenu);
  }
};

var showMoreCat = function () {
  var showMore = document.querySelectorAll('[data-js="show-more-cat"]');

  function showAllLinks(e) {
    var _this = e.target,
      el = _this.index,
      tick = numberOfElements(),
      x;

    if (el % tick == 0) {
      x = el / tick;
    } else {
      x = Math.ceil(el / tick) - 1;
    }

    e.stopPropagation();

    for (var i = x * tick; i < x * tick + tick; i++) {
      if (showMore[i]) {
        var ul = showMore[i].parentElement,
          liList = ul.querySelectorAll("LI");

        if (!showMore[i].classList.contains("shown")) {
          showMore[i].classList.add("shown");
          showMore[i].textContent = showMore[i].getAttribute("data-txt-hide");

          for (var j = 0; j < liList.length; j++) {
            liList[j].style.display = "block";
          }
        } else {
          showMore[i].textContent = showMore[i].getAttribute("data-txt-show");
          showMore[i].classList.remove("shown");

          for (var j = 3; j < liList.length; j++) {
            liList[j].style.display = "none";
          }
        }
      }
    }
  }

  for (var i = 0; i < showMore.length; i++) {
    var divM = showMore[i].parentElement,
      liListM = divM.querySelectorAll("LI");

    showMore[i].index = i;

    showMore[i].textContent = showMore[i].getAttribute("data-txt-show");
    if (liListM.length < 4) showMore[i].style.display = "none";

    showMore[i].onclick = showAllLinks;
  }

  function numberOfElements() {
    var _br = [1200, 768, 0];
    var _box = [4, 3, 2];
    var width = document.body.clientWidth;
    var x, _tick;

    _br.some((el, index) => {
      x = index;
      return width >= el;
    });

    _tick = _box[x];
    return _tick;
  }
};

var showElement = function (element) {
  var body = document.querySelector("body"),
    showLeftSubmenuBtn = body.querySelector('[data-js="' + element + '-btn"]'),
    leftSubmenu = body.querySelector('[data-js="' + element + '"]');

  if (showLeftSubmenuBtn)
    showLeftSubmenuBtn.addEventListener("click", function (e) {
      e.stopPropagation();
      if (!leftSubmenu.classList.contains("open")) {
        leftSubmenu.classList.add("open");
        showLeftSubmenuBtn.classList.add("open");

        body.addEventListener("click", closeElement);
      } else {
        closeElement();
      }
    });
  if (leftSubmenu)
    leftSubmenu.addEventListener("click", function (e) {
      e.stopPropagation();
    });

  function closeElement() {
    leftSubmenu.classList.remove("open");
    showLeftSubmenuBtn.classList.remove("open");
    body.removeEventListener("click", closeElement);
  }
};

function dataLayerPush(obj) {
  if (typeof dataLayer != "undefined") {
    dataLayer.push(obj);
  }
}
